<template lang="html">
  <footer id="footer">
    <div class="container oversized-container">

      <div class="row align-items-center row-top">
        <div class="col-lg-5 col-left col-newsletter">
          <b-form @submit="onSubmit">
            <b-form-input
              v-model="form.email"
              type="email"
              placeholder="Escribe tu correo..."
              required
            ></b-form-input>

            <b-button type="submit" class="btn-submit">Inscribirse</b-button>
          </b-form>
        </div>

        <div class="col-lg col-middle">
          <h6>Sé el primero en enterarte</h6>
          <h4>Regístrate a nuestro newsletter</h4>
        </div>

        <div class="col-lg row mx-0 col-right">
          <div class="box-networks">
            <a class="t-150 btn-network ig" href="#"><i class="fab fa-instagram"></i></a>
            <!-- <a class="t-150 btn-network tw" href="#"><i class="fab fa-twitter"></i></a> -->
            <a class="t-150 btn-network fb" target="_blank" href="https://www.facebook.com/importerr.lutherr"><i class="fab fa-facebook-f"></i></a>
            <!-- <a class="t-150 btn-network lk" href="#"><i class="fab fa-linkedin-in"></i></a> -->
          </div>
          <router-link class="logo" to="/"><img src="public/images/logo-white.svg"></router-link>
        </div>
      </div>

      <div class="row row-bottom">
        <!-- <div class="col-lg-5 col-menus">
          <div class="row">
            <div class="col-6 col-sm-4 col-menu">
              <h6 class="title">Información</h6>

              <p class="my-1">
                <router-link to="/empresa">Empresa</router-link>
              </p>
              <p class="my-1">
                <router-link to="">Bolsa de trabajo</router-link>
              </p>
              <p class="my-1">
                <router-link to="/contacto">Contáctanos</router-link>
              </p>
            </div>

            <div class="col-6 col-sm-4 col-menu">
              <h6 class="title">Mapa del sitio</h6>

              <p class="my-1">
                <router-link to="/">Inicio</router-link>
              </p>
              <p class="my-1">
                <router-link to="/solicitud-de-credito">Solicitud de crédito</router-link>
              </p>
              <p class="my-1">
                <router-link to="">Productos</router-link>
              </p>
            </div>

            <div class="col-6 col-sm-4 col-menu">
              <h6 class="title">Links de acceso</h6>

              <p class="my-1">
                <router-link to="/registrarse">Registrate</router-link>
              </p>
              <p class="my-1">
                <router-link to="/login">Inicia sesión</router-link>
              </p>
              <p class="my-1">
                <router-link to="/recuperar-contrasena">Recuperar contraseña</router-link>
              </p>
            </div>
          </div>
        </div> -->

        <div class="col-sm-6 col-lg col-middle align-self-center">
          <p class="my-2">
            <router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
          </p>
          <p class="my-2">
            <router-link to="/terminos-y-condiciones">Términos y condiciones</router-link>
          </p>
          <p class="my-2">
            <span>Derechos Reservados LUTHERR {{ currentYear }}</span>
          </p>
        </div>

        <div class="col-sm-6 col-lg col-info">
          <p class="my-2">
            <span>crivas@lutherr.com <i class="icon"><i class="far fa-envelope"></i></i></span>
          </p>
          <p class="my-2">
            <span>(+52) 33 3700 9303 <i class="icon"><i class="fas fa-headset"></i></i></span>
          </p>
          <p class="my-2">
            <span>(+52) 33 1624 1784 <i class="icon"><i class="fas fa-headset"></i></i></span>
          </p>
          <p class="my-2">
            <span>Capulín #388, Las Huertas., Tlaquepaque, Mexico, 45589<i class="icon"><i class="far fa-map-marker"></i></i></span>
          </p>

          <div class="box-cert mt-3 mt-lg-4">
            <span>Certificado de pagos</span><br />
            <span class="safe">Compras seguras</span>
          </div>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  data(){
    return{
      currentYear: 0,

      form: {
        email: null
      },
      info: {
        email:null,
        contact:null,
        address:null,
      },
    }
  },

  methods:{

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },
  },

  beforeMount(){
    this.currentYear = new Date().getFullYear();
    this.getInfo();
  }
}
</script>
