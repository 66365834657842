<template lang="html">
  <div id="about-us-page-v2">

    <section class="main-section">
      <div class="placed-backg box-image"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-xl-6 col-image"></div>

          <div class="col-lg-6 col-text">
            <h1 class="mb-2 title-s1 c-primary">¿Quienes somos?</h1>

            <!-- <h5 class="subtitle"></h5> -->
            <p>
              Somos una empresa fundada en Stuttgart Alemania en 1980, siendo principal proveedor de partes de reemplazo (iso 9001/2000). Distribuidores en Europa dentro del mercado de reemplazo y por fabricantes de ejes y remolques, ofreciendo una gran gama para las principales marcas en Europa como lo son:
            </p>
            <p class="f-w-600">
              Renault, Mercedes – Benz, Volvo, Volkswagen
            </p>

            <div class="b-logos">
              <img src="public/images/pages/about-us/logos.png">
            </div>

            <h6 class="mt-4 subtitle">¡CON PRESENCIA EN 13 PAÍSES DE LA UNION EUROPEA EN CENTRO SUDAMÉRICA!</h6>
          </div>
        </div>
      </div>
    </section>

    <MissionComponent></MissionComponent>
  </div>
</template>

<script>
import MissionComponent from './mission-component.vue';
export default {
  components: {
    MissionComponent
  },

  data(){
    return{
      content:null,
      image: null,
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/about')).then((response)=>{
        this.content = response.data.body;
        this.image = response.data.image;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
