<template lang="html">
  <header id="header">
    <div class="header-content">

      <!-- Header menu -->
      <div class="header-menu">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container oversized-container">
            <b-navbar-brand to="/">
              <img src="public/images/logo.png">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"><i class="far fa-bars icon"></i></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <!-- <b-nav-item class="d-lg-none d-xl-inline-flex item-simple" to="/">Inicio</b-nav-item> -->
                <li class="nav-item item-simple">
                  <a class="nav-link" @click="isHActive = !isHActive">Productos <i class="fas fa-chevron-down submenu-icon"></i></a>
                </li>
                <!-- <b-nav-item class="item-simple" to="/promociones">Promociones</b-nav-item> -->
                <!-- <b-nav-item class="item-simple" to="/masnuevo">Lo + nuevo</b-nav-item> -->
                <b-nav-item class="item-simple" to="/solicitud-de-credito">Crédito</b-nav-item>
                <b-nav-item class="item-simple" to="/empresa">Empresa</b-nav-item>
                <b-nav-item class="item-simple" to="/bolsa-de-trabajo">R.H.</b-nav-item>
                <b-nav-item class="item-simple" to="/distribuidor">Distribuidor</b-nav-item>
                <b-nav-item class="item-simple" to="/contacto">Contacto</b-nav-item>
                <li class="d-none d-lg-inline-block nav-item item-icons">
                  <span class="nav-link">
                    <span class="mr-2"></span>
                    <a class="t-150 btn-network" target="_blank" href="https://www.facebook.com/importerr.lutherr">
                      <i class="fab fa-facebook-square"></i>
                    </a>
                  </span>
                </li>
                <li class="d-lg-none nav-item item-simple">
                  <span class="nav-link py-1">
                    <hr class="my-0" />
                  </span>
                </li>
                <b-nav-item class="item-simple d-lg-none" to="/login" v-if="!$root.logged">Log in</b-nav-item>
                <b-nav-item class="item-simple d-lg-none" to="/registrarse" v-if="!$root.logged">Registrarse</b-nav-item>
                <b-nav-item class="item-simple d-lg-none" to="/usuario" v-if="$root.logged"><i class="fas fa-user"></i> Mi Cuenta | Bienvenido <strong>{{$root.user.name}}</strong></b-nav-item>
              </b-navbar-nav>

              <!-- Right aligned nav items -->
              <!-- <b-navbar-nav class="ml-auto">
                <b-nav-item class="item-sm" to="/nosotros">Sobre nosotros</b-nav-item>
                <b-nav-item class="item-sm" to="">Comparar</b-nav-item>
                <b-nav-item class="item-sm" to="/contacto">Contáctanos</b-nav-item>

                <li class="d-lg-none nav-item item-line">
                  <a class="nav-link"><hr class="my-0" /></a>
                </li>
                <b-nav-item class="d-lg-none item-simple" to="/login" v-if="!$root.logged"><i class="far fa-user"></i> Iniciar sesión</b-nav-item>
                <b-nav-item class="d-lg-none item-simple" to="/registrarse" v-if="$root.logged"><i class="fas fa-user"></i> Registrarse</b-nav-item>
                 <b-nav-item class="d-lg-none item-simple" to="/usuario" v-if="$root.logged"><i class="fas fa-users-cog"></i> Mi cuenta</b-nav-item>
              </b-navbar-nav> -->
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Header options  -->
      <div class="header-options">
        <!-- <b-navbar toggleable="lg" type="dark" variant="info"> -->
        <b-navbar type="dark" variant="info">
          <div class="container oversized-container">
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-form class="search-form" @submit="onSubmitSearch">
                  <b-form-input placeholder="Busca algun producto..." v-model="formSearch.keywords"></b-form-input>

                  <b-form-select v-model="formSearch.category" >
                    <b-form-select-option :value="null">Todas las Categorías</b-form-select-option>
                    <b-form-select-option v-for="(cat,indx) in categories" :key="indx" :value="cat.id">{{cat.name}}</b-form-select-option>
                  </b-form-select>

                  <b-button class="btn-search" type="submit"><span class="d-none d-sm-inline">Buscar</span><i class="fas fa-search d-sm-none"></i></b-button>
                </b-nav-form>
              </b-navbar-nav>

              <b-navbar-nav class="ml-lg-auto navbar-nav-options" >
                <b-form-select v-model="formSearch.brands_id" class="mt-2 mt-lg-0 brand-select">
                    <b-form-select-option :value="null">Marca</b-form-select-option>
                    <b-form-select-option v-for="(cat,indx) in brands" :key="indx" :value="cat.id">{{cat.name}}</b-form-select-option>
                  </b-form-select>

                <!-- <b-nav-item class="icon-item" to="/mis-favoritos"><img src="public/images/shared/ic-favorites.svg"></b-nav-item> -->
                <b-nav-item class="icon-item mt-2 mt-lg-0 cart" to="/cart"><div><img src="public/images/shared/ic-cart.svg"> <u>{{$root.cartCount}}</u></div></b-nav-item>
                <b-nav-item class="item-simple d-none d-lg-inline-block" to="/login" v-if="!$root.logged">Log in</b-nav-item>
                <b-nav-item class="item-simple d-none d-lg-inline-block" to="/registrarse" v-if="!$root.logged">Registrarse</b-nav-item>
                 <b-nav-item class="item-simple d-none d-lg-inline-block my-account" to="/usuario" v-if="$root.logged">
                  <div>
                    Bienvenido <strong>{{$root.user.name}}</strong><br />
                    <span>Mi cuenta</span>
                  </div>
                  <div class="ic-profile"></div>
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

    </div>

    <!-- Categorías -->
    <div class="t-250 products-menu" v-bind:class="{ active: isHActive }">
      <div class="menu-bg" @click="isHActive = false"></div>

      <div class="tablist" role="tablist">
        <div class="t-250 box-title" @click="isHActive = false">
          <h4>CATEGORÍAS</h4>
          <!-- <br>
          <h5 v-on:click="$router.push('/productos');">Categorias</h5> -->
          <!-- <i class="fas fa-chevron-left icon"></i> -->
          <i class="fas fa-chevron-square-left icon"></i>
        </div>

        <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
          <b-card-header header-tag="header" role="tab">
            <div v-if="category.subcategories" class="btn-toggle">
              <router-link :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
              <span class="fake-toggle" v-b-toggle="'accordion-'+pcatIndex"></span>
            </div>
            <router-link v-else class="btn-toggle" :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
          </b-card-header>

          <b-collapse v-if="category.subcategories" :id="'accordion-'+pcatIndex" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <ul class="ul-sub-options">
                <li v-for="subcategory in category.subcategories">
                  <router-link class="t-250" :to="'/subcategoria/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
                </li>
              </ul>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <!--  -->

  </header>
</template>

<script>
export default {
  data(){
    return{
      isHActive: false,

      categories:[],

      formSearch: {
        keywords: null,
        category: null,
        brands_id:null,
      },
      brands:[],

    }
  },

  methods: {
      onSubmitSearch(event) {
        event.preventDefault();

        this.$router.push({path: '/busqueda', query: {keywords:this.formSearch.keywords,category:this.formSearch.category}});
      },
      getCategories(){
				axios.get(tools.url("/api/categories")).then((response)=>{
            this.categories = response.data;
			    	this.$parent.inPetition=false;
			    }).catch((error)=>{
			    });
			},
      getBrands(){
        axios.get("https://lutherr.com/api/brands").then((response)=>{
				// axios.get(tools.url("/api/brands")).then((response)=>{
            this.brands = response.data;
			    	this.$parent.inPetition=false;
			    }).catch((error)=>{
			    });
			},
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isHActive = false;
    },
    'formSearch.brands_id':function(){
        this.$router.push({path: '/busqueda', query: {brands_id:this.formSearch.brands_id}});
    }
  },
  mounted(){
      this.getCategories();
      this.getBrands();
  }
}
</script>
