<template lang="html">
  <section class="mission-vision-section-comp">
    <div class="container">
      <div class="row justify-content-between">
        <!-- <div class="col-12 col-title">
          <h2>Nuestros servicios</h2>
        </div> -->

        <div class="col-md-6 col-lg-6 col-service">
          <h5>MISIÓN</h5>
          <hr />
          <p>
            Nuestra misión es, abastecer con nuestra gama de productos a nuestros clientes y futuros distribuidores, con el fin de desplazar estratégicamente nuestra gran variedad de productos para el beneficio y plena satisfacción del consumidor así como al usuario final, ofreciendo los productos de la más alta calidad con el valor más accesible del mercado.
          </p>
        </div>

        <div class="col-md-6 col-lg-6 col-service">
          <h5>VISIÓN</h5>
          <hr />
          <p>
             Manufacturar desarrollando nuestros productos, teniendo como base los más altos estándares de calidad en conjunto con una atención personalizada, obteniendo un vinculo mercante con cada uno de nuestros clientes, manteniendo relaciones comerciales exitosas a largo plazo.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
