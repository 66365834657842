<template lang="html">
  <div class="placed-backg contact-page-" id="page-info-t1">
    <div class="bg-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.jpg)' }"></div>

    <section class="container oversized-container content-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 text-center col-info">
          <h1 class="mb-2 page-title">Contacto</h1>

          <h6 class="f-w-600">Dirección</h6>
          <p>
            Capulín #388, Las Huertas., Tlaquepaque, Mexico, C.P. 45589
          </p>

          <br />
          <h6 class="f-w-600">Teléfono</h6>
          <p>
            (+52) 33 1624 1784, (+52) 33 3700 9303
          </p>

          <br />
          <h6 class="f-w-600">Correo</h6>
          <p>
            crivas@lutherr.com
          </p>
        </div>

        <div class="col-lg-5 col-xl-4 col-info">
          <b-form @submit="onSubmit">
            <b-form-group>
              <b-form-input
                v-model="form.name"
                type="text"
                required
                placeholder="Nombre"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.email"
                type="email"
                required
                placeholder="Correo electrónico"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.phone"
                type="text"
                required
                placeholder="Teléfono"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-textarea
                v-model="form.msg"
                placeholder="Mensaje"
                required
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class="btn-send">Enviar</b-button>
            </p>
          </b-form>

        </div>
      </div>
    </section>

    <!-- <section class="container-fluid px-0 map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119452.69716917786!2d-103.40545358012501!3d20.67377770932267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b18cb52fd39b%3A0xd63d9302bf865750!2sGuadalajara%2C%20Jal.%2C%20M%C3%A9xico!5e0!3m2!1ses!2sca!4v1627506372341!5m2!1ses!2sca" allowfullscreen="" loading="lazy"></iframe>
    </section> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        name: null,
        email: null,
        phone: null,
        msg: null
      },
      info: {
        contact:null,
      },
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/contact"), this.form).then((response)=>{
          this.makeToast('success', 'Informacion enviada correctamente', 'Mensaje enviado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            phone: null
          };
          alert('Informacion enviada correctamente');
        }).catch( (error)=> {
            // console.log(error.response);
            let errors = error.response.data;
            // console.log(errors);
            this.makeToast('danger', 'Hubo un error al mandar la informacion', 'Error');

            alert('Hubo un error al mandar la informacion');
      });
    }
  },

  beforeMount(){
    // this.getInfo();

    if(this.$route.query.p){
      this.form.msg = 'Me intresa el producto '+ this.$route.query.p;
    }
  }
}
</script>
